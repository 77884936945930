<style lang="scss" scoped>
.recruit_canvas {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: row-reverse;
	.remind {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0.4rem 0;
		.remind_text {
			font-size: 0.14rem;
			letter-spacing: 2em;
			display: inline-block;
			transform: rotate(90deg);
			color: $danger;
		}
	}
	.canvas_box {
		flex: 1;
		// width: 100vw;
		overflow: hidden;
		border: 1px dashed #d4d4d4;
		background-color: #fff;

		&#canvas_map {
			width: 100vw;
			height: 100vh;
		}
	}

	.btn_box {
		flex-shrink: 0;
		height: 100vh;
		padding: 0.4rem 0.1rem;
		font-size: 14px;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		box-sizing: border-box;

		.del_btn,
		.agree_btn,
		.add_btn {
			padding: 0.4rem 0.1rem;
			border-radius: 6px;
			display: flex;
			flex-direction: column;
			margin: 0.1rem 0;
			span {
				display: inline-block;
				transform: rotate(90deg);
			}
		}
		.del_btn {
			border: 1px solid $border_color;
			color: $font_color_main;
		}
		.add_btn {
			color: #fff;
			background-color: $success;
		}
		.agree_btn {
			color: #fff;
			background: $color_main;
		}
	}
}

/* 竖屏 css */
/* @media screen and (orientation: portrait) {
    .recruit_canvas .canvas_box {
        top: 20px;
        left: 10%;
        width: 80vw;
        height: 80vh;
        transform: translateY(0);
    }

    .recruit_canvas .btn_box {
        width: 60%;
        left: 20%;
        top: 86%;
        transform: rotate(0);
        display: flex;
    }
} */
/* 横屏 css */
// @media screen and (orientation: landscape) {
//     .recruit_canvas .canvas_box {
//         transform: translateY(0);
//     }

//     .recruit_canvas .btn_box {
//         transform: rotate(0);
//     }
// }
</style>

<template>
	<div class="recruit_canvas">
		<div class="remind">
			<span class="remind_text" v-for="(text, index) in remindText" :key="index" v-text="text"></span>
		</div>
		<div class="canvas_box" ref="canvasRef">
			<canvas ref="canvasMapRef" id="canvas_map" width="100" height="100"></canvas>
		</div>

		<div class="btn_box" v-if="cancel || add || agree">
			<span v-if="cancel" class="del_btn" @click="cancelHandle">
				<span v-for="(text, index) in cancelText" :key="index" v-text="text"></span>
			</span>
			<span v-if="clear" class="add_btn" @click="clearCanvasHandle">
				<span v-for="(text, index) in clearText" :key="index" v-text="text"></span>
			</span>
			<span v-if="add" class="add_btn" @click="makeCanvasHandle">
				<span v-for="(text, index) in addText" :key="index" v-text="text"></span>
			</span>
			<span v-if="agree" class="agree_btn" @click="makeCanvasHandle($event, true)">
				<span v-for="(text, index) in agreeText" :key="index" v-text="text"></span>
			</span>
		</div>
	</div>
</template>

<script>
import { Toast } from 'vant';
import SignaturePad from 'signature_pad';
import utils from '@/assets/js/base.js';

/**
 * 提供的事件有：
 * cancel   取消
 * clear    清空画板
 * agree    签名并同意，参数：签名图片
 * add      签名并新增，参数：签名图片
 */

export default {
	name: 'SignaturePad', // 签名组件
	props: {
		// 顶部提醒文字
		remindText: {
			type: String,
			required: false,
			default: '请用正楷签字，需为他人一同购买保险时，请增加签字确认。',
		},

		// 取消按钮文字
		cancelText: {
			type: String,
			required: false,
			default: '取消',
		},

		// 取消按钮是否显示
		cancel: {
			type: [String, Boolean],
			required: false,
			default: true,
		},

		// 清空按钮是否显示
		clear: {
			type: [String, Boolean],
			required: false,
			default: true,
		},

		// 清空按钮文字
		clearText: {
			type: String,
			required: false,
			default: '清空',
		},

		// 增加签名按钮文字
		addText: {
			type: String,
			required: false,
			default: '增加签字确认人',
		},

		// 增加签名按钮是否显示
		add: {
			type: [String, Boolean],
			required: false,
			default: true,
		},

		// 确认签字并跳转购买保险按钮文字
		agreeText: {
			type: String,
			required: false,
			default: '确认签字并跳转购买保险',
		},

		// 确认签字并跳转购买保险按钮是否显示
		agree: {
			type: [String, Boolean],
			required: false,
			default: true,
		},
	},
	data() {
		return {
			canvasNode: null,
		};
	},
	mounted() {
		this.initialHandle();
		window.addEventListener('resize', this.initialHandle, false);
	},
	methods: {
		// 初始化
		initialHandle() {
			const _canvasBox = this.$refs.canvasRef;
			const _canvas = this.$refs.canvasMapRef;
			if (!_canvasBox || !_canvas) {
				return false;
			}

			_canvas.width = _canvasBox.clientWidth;
			_canvas.height = _canvasBox.clientHeight;

			this.clearCanvasHandle();
			this.canvasNode = new SignaturePad(_canvas, {
				minWidth: 2,
				maxWidth: 2,
				penColor: 'rgb(0, 0, 0)',
			});
		},

		// 取消
		cancelHandle() {
			this.$emit('cancel');
		},

		// 清除画板
		clearCanvasHandle() {
			if (this.canvasNode) {
				this.canvasNode.clear();
				this.$emit('clear');
			}
		},

		// 清除画板
		clearCanvas() {
			if (this.canvasNode) {
				this.canvasNode.clear();
			}
		},

		// 生成图片
		async makeCanvasHandle(e, isOver = false) {
			const canvasNode = this.canvasNode;
			// 重新初始化画布
			if (!canvasNode) {
				this.initialHandle();
			}

			// 是否签字
			if (canvasNode.isEmpty()) {
				Toast.fail({
					message: '您还没有签名',
					className: 'toast_horizontal',
				});
				return false;
			}

			// 图像旋转二次处理
			const _boxWidth = window.innerWidth;
			const _boxHeight = window.innerHeight;
			const _signImg = canvasNode.toDataURL('image/png', 0.6);
			let src;
			// 将图片翻转, 统一横竖屏下返回的图片宽高一致
			if (_boxWidth < _boxHeight) {
				src = await utils.rotateBase64Img(_signImg, -90);
			} else {
				src = _signImg;
			}
			let blob = utils.base64toFile(src);
			if (isOver) {
				this.$emit('agree', blob);
			} else {
				this.$emit('add', blob);
				this.clearCanvas();
			}
		},
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.initialHandle, false);
	},
};
</script>
