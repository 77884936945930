<style lang="scss" scoped>
.body {
	padding: $body_padding;
}
.show_signature {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	box-sizing: border-box;
}
article {
	margin: 0.4rem 0 0.6rem;
}
::v-deep p {
	line-height: normal;
	font-size: medium;
}
.btn_content {
	display: flex;
	align-items: center;
	.time {
		color: #fff;
		margin-left: 0.3rem;
	}
}

// 签名
.signature {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: #fff;
}
</style>

<template>
	<div class="body" :class="{ show_signature: showSignature }">
		<article v-html="article">告知书内容</article>
		<van-button class="loading_btn" @click="agree" :disabled="isReading" type="info" block="" size="normal">
			<div class="btn_content">
				<span>已阅读并同意签收以上内容</span>
				<van-count-down v-show="isReading" class="time" :time="3000" @finish="finish">
					<template #default="timeData">
						<span>{{ timeData.seconds }}{{ $t('sec') }}</span>
					</template>
				</van-count-down>
			</div>
		</van-button>

		<!-- 签名 -->
		<signature-pad v-if="showSignature" class="signature" @cancel="signatureCancel" @add="signatureAdd" @agree="signatureAgree"></signature-pad>
	</div>
</template>

<script>
import { Toast } from 'vant';
import signaturePad from '@/components/signaturePad.vue';
import { Button, CountDown } from 'vant';
import { http_getNotificationById, http_uploadFile, http_saveSignature } from '@/request/notification.js';

export default {
	name: 'fixedCodeSecurityBook', // 安全告知书
	components: {
		[Toast.name]: Toast,
		[Button.name]: Button,
		[CountDown.name]: CountDown,
		signaturePad,
	},
	data() {
		return {
			id: '', // 告知书模板id
			companyName: '', // 公司名称
			nickname: '', // 俱乐部名称

			signatureIds: [], // 签名信息id

			info: {}, // 告知书信息
			isReading: true,
			showSignature: false,
		};
	},
	computed: {
		// 告知书内容占位符格式化
		article() {
			let r = '';
			if (this.info.content) {
				r = this.info.content.replace(/#company#/g, this.companyName);
				r = r.replace(/#signature#/g, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
				r = r.replace(/#date#/g, this.$base.getDateChinese());
			}
			return r;
		},
	},
	beforeRouteEnter(to, from, next) {
		// 不让从投保信息填写页面退过来
		if (from.name === 'fixedCodeWelcome' || from.path.includes('/fixedCodeInsure/welcome')) {
			next();
		}
	},
	created() {
		this.getQuery();
		this.$base.setUserTitle(this.nickname);
		this.getNotification(this.id);
	},
	methods: {
		// 获取参数
		getQuery() {
			this.id = this.$route.query.notificationId;
			this.companyName = this.$route.query.companyName;
			this.nickname = this.$route.query.nickname;
		},
		setTitle(text = 'ㅤ') {
			this.$base.setTitle(text);
		},

		// 获取告知书配置
		getNotification(id) {
			http_getNotificationById(id).then(res => {
				this.info = res;
			});
		},

		finish() {
			this.isReading = false;
		},

		agree() {
			// 打开签名
			this.showSignature = true;
		},

		// 取消签名
		signatureCancel() {
			this.showSignature = false;
		},

		// 确定并新增签名
		signatureAdd(file) {
			this.saveSignature(file);
		},

		// 确定签名并跳转
		signatureAgree(file) {
			// 跳转到保险购买页面
			this.saveSignature(file).then(this.go);
		},

		// 保存签名信息
		saveSignature(file) {
			Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: '提交中...',
				className: 'toast_horizontal',
			});
			return new Promise(resolve => {
				this.submitSignatureFile(file)
					.then(url => {
						this.submitSignature(url).then(resolve);
					})
					.finally(() => {
						Toast.clear();
					});
			});
		},

		// 提交签名信息
		submitSignature(url) {
			return new Promise(resolve => {
				let send = {
					vid: this.info.vid,
					signatureUrl: url,
					companyName: this.companyName,
				};
				http_saveSignature(send).then(res => {
					this.signatureIds.push(res.id);
					resolve();
				});
			});
		},

		// 提交签名文件
		submitSignatureFile(file) {
			return new Promise(resolve => {
				let formData = new FormData();
				formData.append('file', file);

				http_uploadFile(formData).then(url => {
					resolve(url);
				});
			});
		},

		go() {
			let query = this.$route.query;
			let newQuery = {
				signatureIds: this.signatureIds.join(','),
				...query,
			};
			if (query.payType === '1') {
				// 非渠道支付
				this.$router.push({
					path: 'indexOneselfPay',
					query: newQuery,
				});
			} else if (query.payType === '2') {
				// 渠道支付
				this.$router.push({
					path: 'fixedCodeInsUserPay',
					query: newQuery,
				});
			}
		},
	},
};
</script>
